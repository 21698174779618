import React, { useState } from "react";

// ReactComponents
import { ReactComponent as CarryingTwo } from "./svg/glyphicons-humans-47-carrying-two.svg";
import { ReactComponent as CarryingOnBack } from "./svg/glyphicons-humans-48-carrying-on-back.svg";
import { ReactComponent as PartnersHandshake } from "./svg/glyphicons-humans-73-partners-handshake.svg";
import { ReactComponent as PartnersHandshakeFormal } from "./svg/glyphicons-humans-74-partners-handshake-formal.svg";
import { ReactComponent as PartnersHandsClap } from "./svg/glyphicons-humans-75-partners-hands-clap.svg";
import { ReactComponent as ChildrenPlaying } from "./svg/glyphicons-humans-76-children-playing.svg";
import { ReactComponent as Queue } from "./svg/glyphicons-humans-81-queue.svg";
import { ReactComponent as HelpingElderly } from "./svg/glyphicons-humans-106-helping-elderly.svg";
import { ReactComponent as GroupMen } from "./svg/glyphicons-humans-111-group-men.svg";
import { ReactComponent as GroupWomen } from "./svg/glyphicons-humans-112-group-women.svg";
import { ReactComponent as GroupWomenMan } from "./svg/glyphicons-humans-113-group-women-man.svg";
import { ReactComponent as GroupMenWoman } from "./svg/glyphicons-humans-114-group-men-woman.svg";
import { ReactComponent as PartnersWaving } from "./svg/glyphicons-humans-115-partners-waving.svg";
import { ReactComponent as PartnersWavingMixed } from "./svg/glyphicons-humans-116-partners-waving-mixed.svg";

export default function App() {
  let questions = [];
  for (let i = 0; i < 15; i++) {
    questions.push(createQuestion());
  }

  function createQuestion() {
    // var array = ['fa fa-ambulance', 'fa fa-car', 'fa fa-rocket', 'fa fa-thumbs-o-up', 'fa fa-video-camera', 'fa fa-ticket',	'fa fa-snowflake-o', 'fa fa-motorcycle', 'fa fa-child', 'fa fa-ban'];
    var array = [
      "fa-solid fa-hippo",
      "fa-solid fa-fish",
      "fa-solid fa-worm",
      "fa-solid fa-spider",
      "fa-solid fa-horse",
      "fa-solid fa-frog",
      "fa-solid fa-dog",
      "fa-solid fa-cat",
    ];

    // var array = [
    //   <CarryingTwo fill="white" height={100} width={100} />,
    //   <CarryingOnBack fill="white" height={100} width={100} />,
    //   <PartnersHandshake fill="white" height={100} width={100} />,
    //   <PartnersHandshakeFormal fill="white" height={100} width={100} />,
    //   <PartnersHandsClap fill="white" height={100} width={100} />,
    //   <ChildrenPlaying fill="white" height={100} width={100} />,
    //   <Queue fill="white" height={100} width={100} />,
    //   <HelpingElderly fill="white" height={100} width={100} />,
    //   <GroupMen fill="white" height={100} width={100} />,
    //   <GroupWomen fill="white" height={100} width={100} />,
    //   <GroupWomenMan fill="white" height={100} width={100} />,
    //   <GroupMenWoman fill="white" height={100} width={100} />,
    //   <PartnersWaving fill="white" height={100} width={100} />,
    //   <PartnersWavingMixed fill="white" height={100} width={100} />
    // ];

    array = arrayShuffle(array);

    // Question
    const q = array.slice(0, 3);
    console.log(q);
    //Random 5 Items for creating the answers
    const ansSlice = array.slice(0, 5);

    //Add the correct answer to the Set
    let a = new Set([q]);
    //Till we have 4 unique answers, keep adding
    while (a.size < 4) {
      a.add(arrayShuffle(ansSlice).slice(0, 3));
    }

    //Suffle the answers
    const aArray = arrayShuffle(Array.from(a));

    let ao = [];
    aArray.forEach((item) => {
      if (q === item)
        ao.push({
          answerText: item,
          isCorrect: true,
        });
      else ao.push({ answerText: item, isCorrect: false });
    });

    return {
      questionText: q,
      answerOptions: ao,
    };
  }

  function arrayShuffle(array) {
    for (let index = array.length - 1; index > 0; index--) {
      const newIndex = Math.floor(Math.random() * (index + 1));
      [array[index], array[newIndex]] = [array[newIndex], array[index]];
    }
    return array;
  }

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);

  const handleAnswerOptionClick = (isCorrect) => {
    document.getElementById("question-text").style.display = "flex";
    document.getElementById("answer-section").style.display = "none";

    if (currentQuestion < questions.length - 1) {
      setTimeout(function () {
        document.getElementById("question-text").style.display = "none";
        document.getElementById("answer-section").style.display = "flex";
      }, 2000);
    }

    if (isCorrect) {
      setScore(score + 1);
    }

    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
    }
  };

  if (currentQuestion === 0) {
    setTimeout(function () {
      document.getElementById("question-text").style.display = "none";
      document.getElementById("answer-section").style.display = "flex";
    }, 2000);
  }

  return (
    <div className="app">
      {showScore ? (
        <div className="score-section">
          Απαντήσατε σωστά στις {score} από τις {currentQuestion + 1} ερωτήσεις
        </div>
      ) : (
        <>
          <div className="question-section">
            <div className="question-count">
              <span>Ερώτηση {currentQuestion + 1}</span>/{questions.length}
            </div>
            <div id="question-text">
              {/* {questions[currentQuestion].questionText} */}
              <i
                className={questions[currentQuestion].questionText[0]}
                style={{ fontSize: "70px" }}
              ></i>
              <i
                className={questions[currentQuestion].questionText[1]}
                style={{ fontSize: "70px" }}
              ></i>
              <i
                className={questions[currentQuestion].questionText[2]}
                style={{ fontSize: "70px" }}
              ></i>
            </div>
          </div>
          <div id="answer-section" style={{ display: "none" }}>
            {questions[currentQuestion].answerOptions.map((answerOption) => (
              <button
                onClick={() => handleAnswerOptionClick(answerOption.isCorrect)}
              >
                {/* {answerOption.answerText} */}
                <i
                  className={answerOption.answerText[0]}
                  style={{ fontSize: "70px" }}
                ></i>
                <i
                  className={answerOption.answerText[1]}
                  style={{ fontSize: "70px" }}
                ></i>
                <i
                  className={answerOption.answerText[2]}
                  style={{ fontSize: "70px" }}
                ></i>
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
